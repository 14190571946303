<template>
  <ul id="element-settings" class="uk-switcher uk-margin">
    <li class="uk-active">
      <div class="uk-width-1-1">
        <div class="uk-background-default uk-padding-small">
          <!--
          <div class="uk-form-stacked">
            <div class="uk-width-medium uk-margin">
              <label class="uk-form-label">Schnittstelle</label>
              <select class="uk-select" v-model="form.api">
                <option value="webkfz">Webkfz</option>
                <option value="aos">AOS</option>
              </select>
            </div>
            <div class="uk-margin">
              <div class="uk-grid uk-child-width-1-3@m" uk-grid>
                <div>
                  <label class="uk-form-label">Hersteller</label>
                  <select class="uk-select" v-model="form.make">
                    <option value="" disabled>Bitte wählen</option>
                    <option v-for="make in makeModels" :key="make.value" :value="make.value">{{ make.label }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
    </li>
    <li>
      <div class="uk-width-1-1 uk-margin">
        <div>
          <p>Keine weiteren Einstellungen</p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {

  },
  data () {
    return {
      makeModels: [],
      form: {}
    }
  },
  mounted () {
    //
  },
  methods: {
    //
  }
}
</script>
